<template>
    <div class='instructions'>
        <div class="content">
            <div class="contItem user">
                <div>Hi</div>
                <div>{{ dataInfo.userName }}</div>
            </div>
            <div class="contItem head">
                <div class="logo">
                    <img class="img" :src="dataInfo.logoImg" alt="">
                </div>
                <div class="headTitle">
                    <div class="title">{{ dataInfo.brandName }}</div>
                    <div class="subtitle">{{ dataInfo.subtitle }}</div>
                </div>
                <div class="statement">
                    <li v-for="(item, index) in dataInfo.statement" :key="index">{{ item }}</li>
                </div>
            </div>
            <div class="contItem details">
                <div class="delHead">Hi， {{ dataInfo.userName }}</div>
                <div class="decoding">{{ dataInfo.decoding }}</div>
                <div class="text">{{ dataInfo.decodingText }}</div>
            </div>
            <div class="contItem usage">
                <div class="usaHead">复龄冻龄逆龄定制</div>
                <div v-if="dataInfo.usaTip">请根据盒子内部产品标号依次服用</div>
                
                <div class="usageData">
                    <div class="usageItem" v-for="(item, index) in dataInfo.Consolidation" :key="index">
                        <!-- methodImg -->
                        <img class="img" :src="item.productPicture" alt="" mode="widthFix" style="width:100%;height:100%">
                        <div>{{ item.usingMethod }}</div>
                    </div>
                    <!-- <div class="usageItem" >
                        <img class="img" src="https://image.giantgocloud.com/www//ImageMapping/image/20230317/A05F2560562640D5A490BFB445110558.jpg" alt="" mode="widthFix" style="width:100%;height:100%">
                        <div>4567897987987</div>
                    </div>
                    <div class="usageItem" >
                        <img class="img" src="https://image.giantgocloud.com/www//ImageMapping/image/20230317/A05F2560562640D5A490BFB445110558.jpg" alt="" mode="widthFix" style="width:100%;height:100%">
                        <div>4567897987987</div>
                    </div>
                    <div class="usageItem" >
                        <img class="img" src="https://image.giantgocloud.com/www//ImageMapping/image/20230317/A05F2560562640D5A490BFB445110558.jpg" alt="" mode="widthFix" style="width:100%;height:100%">
                        <div>4567897987987</div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="content">
            <div class="contItem food">
                <div class="foodtitle">亚洲食疗大师——欧阳英食疗建议</div>
                <div class="foodImg" v-if="dataInfo.coreData">
                    <img class="img" :src="dataInfo.coreData.foodTreatPicture" alt="" mode="widthFix">
                </div>
            </div>
            <div class="contItem component">
                <!-- <div class="comHead">For {{ dataInfo.userName }}</div> -->
                <div class="comtitle">成分系列</div>
                <div class="componentData">
                    <div class="compItem" v-for="(item, index) in dataInfo.Component" :key="index">
                        <div class="compImg">
                            <img class="img" :src="item.componentPicture" alt="" mode="widthFix">
                        </div>
                        <div class="compText" >{{ item.componentMethod }}</div>
                    </div>
                </div>
            </div>
            
            <div class="contItem certificate">
                <img class="img" :src="certificateImg1" mode="widthFix" v-if="dataInfo.productType">
                <img class="img" :src="certificateImg2" mode="widthFix" v-else>
            </div>
            <div class="contItem introduce">
                <div class="introduceItem" v-if="dataInfo.productType">
                    <div class="basics" v-for="(bas, index) in productDetails" :key="index">
                        <div class="basicsItem">
                            <div class="lable">{{ bas.lableLf }}</div>
                            <div>{{ bas.dataValueLf }}</div>
                        </div>
                        <div class="basicsItem">
                            <div class="lable">{{ bas.lableRt }}</div>
                            <div>{{ bas.dataValueRt }}</div>
                        </div>
                    </div>
                    <div class="introduceImg">
                        <img class="img" :src="productDetailsImg" alt="" mode="widthFix">
                    </div>
                </div>
                <div class="introduceItem" v-else>
                    <div v-for="(bas, index) in productDetails2" :key="index">
                        <div class="basics" v-if="bas.lableRt">
                            <div class="basicsItem">
                                <div class="lable">{{ bas.lableLf }}</div>
                                <div>{{ bas.dataValueLf }}</div>
                            </div>
                            <div class="basicsItem">
                                <div class="lable">{{ bas.lableRt }}</div>
                                <div>{{ bas.dataValueRt }}</div>
                            </div>
                        </div>
                        <div class="basics" v-else>
                            <div class="basicsItemSingle">
                                <div class="lable">{{ bas.lableLf }}</div>
                                <div>{{ bas.dataValueLf }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetFormulaInfoByOrder } from '@/api/index'

export default {
    components: {

    },
    data() {
        return {
            dataInfo: {},
            productDetailsImg: `${this.$imgDomain}/www/HealthingCore/Instructionsbtom.png`,
            certificateImg1: `${this.$imgDomain}/www/HealthingCore/InstructionsCer1.png`,
            certificateImg2: `${this.$imgDomain}/www/HealthingCore/InstructionsCer2.png`,
            productDetails: [
                {
                    lableLf: '【产品名称】',
                    dataValueLf: '',
                    lableRt: '【贮存条件】',
                    dataValueRt: '请置于阴凉干燥处保存'
                },
                {
                    lableLf: '【生产日期】',
                    dataValueLf: '见外包装盒底部',
                    lableRt: '【保质期】',
                    dataValueRt: '24个月'
                },
                {
                    lableLf: '【生产定制企业】',
                    dataValueLf: '',
                    lableRt: '【生产许可证编号】',
                    dataValueRt: 'IC-HACCP-1911008'
                },
            ],
            productDetails2: [
                {
                    lableLf: '【产品名称】',
                    dataValueLf: '生命之鼎复龄冻龄逆龄定制',
                    lableRt: '【贮存条件】',
                    dataValueRt: '请置于阴凉干燥处保存'
                },
                {
                    lableLf: '【生产日期】',
                    dataValueLf: '见外包装盒底部',
                    lableRt: '【保质期】',
                    dataValueRt: '24个月'
                },
                {
                    lableLf: '【委托单位】',
                    dataValueLf: '杭州复华健康管理有限责任公司',
                },
                {
                    lableLf: '【委托单位地址】',
                    dataValueLf: '浙江省杭州市萧山区宁围街道望京商务中心',
                },
                {
                    lableLf: '【生产单位】',
                    dataValueLf: '福州福万生物技术工程有限公司',
                },
                {
                    lableLf: '【生产单位地址】',
                    dataValueLf: '福建省福州市长乐区文武砂镇协创工业园',
                },
                {
                    lableLf: '【食品生产许可证号】',
                    dataValueLf: 'SC12735018200576',
                },
            ],
        };
    },
    props: {

    },
    created() {
        this.getinstructionsData()
    },
    mounted() {

    },
    methods: {
        getinstructionsData() {
            // const orderId = this.$query('orderId');
            // const dataValue = this.$query('dataValue');
            const orderId = '2023031727822250';
            const dataValue = 'FY0005-Cr00001';
            const parmas = {
                OrderId: orderId,
                DataValue: dataValue
            }
            GetFormulaInfoByOrder(parmas).then(res => {
                const { data } = res;
                if (data) {
                    this.handleFormulaData(data);
                    this.handleLogoData(data)
                    this.dataInfo = data;
                }
            })
        },
        //配方/成分数据处理
        handleFormulaData(data) {
            // 配方
            data.Consolidation = [];
            // 成分
            data.Component = [];
            if (data.coreData) {
                data.Consolidation.push(
                    {
                        productPicture: data.coreData.coreProductPicture,
                        usingMethod: data.coreData.coreUsingMethod,
                    }
                );
                data.Component.push(
                    {
                        componentPicture: data.coreData.coreComponentPicture,
                        componentMethod: data.coreData.coreComponent,
                    }
                );
                
            }
            if (data.oneData) {
                data.Consolidation.push(
                    {
                        productPicture: data.oneData.coreComponentPicture,
                        usingMethod: data.oneData.coreComponent,
                    }
                );
                data.Component.push(
                    {
                        componentPicture: data.oneData.coreComponentPicture,
                        componentMethod: data.oneData.coreComponent,
                    }
                );
            }
            if (data.twoData) {
                data.Consolidation.push(
                    {
                        productPicture: data.twoData.coreComponentPicture,
                        usingMethod: data.twoData.coreComponent,
                    }
                );
                data.Component.push(
                    {
                        componentPicture: data.twoData.coreComponentPicture,
                        componentMethod: data.twoData.coreComponent,
                    }
                );
            }
            console.log(data.Consolidation,"data.Component")
        },
        // logo图片/标题内容处理
        handleLogoData(item) {
            // 产品名称
            this.productDetails[0].dataValueLf = item.productName;
            this.productDetails2[0].dataValueLf = item.productName;
            // 生产日期
            this.productDetails[1].dataValueLf = item.productData;
            this.productDetails2[1].dataValueLf = item.productData;

            // 定制企业
            this.productDetails[2].dataValueLf = item.websiteName;
            const statement = [`本产品为${item.productName}，不用于治疗、诊断、预防或治愈疾病。`, '本产品采用密封包装,收到请尽快使用']
            if (item.brandName == '江南西') {
                item.logoImg = `${this.$imgDomain}/www/HealthingCore/江南西.png`;
                item.subtitle = "开启你的代谢健康新生活";
                item.statement = statement;
                item.decoding = "身体有个性,定制你健康";
                item.decodingText = "江南西健康管理有限公司，致力护航人类生命质量，打造全球一站式大型健康产业平台。与中国军科院强强联合，建立一站式国际健康医疗个性化定制服务，拥有全球资质的专家及教授资源，获得了AABB,CAP,NRL三大国际认证，争做学术化，规模化，产业化的健康产业平台，为国人的健康保驾护航。";
                item.usaTip = true;
                item.productType = true;

            } else if (item.brandName == '玛雅岛') {
                item.logoImg = `${this.$imgDomain}/www/HealthingCore/玛雅岛.png`;
                item.subtitle = "开启你的代谢健康新生活";
                item.statement = statement;
                item.decoding = "欢迎进入全新的代谢管理世界，还你完美曲线，实现健康人生";
                item.decodingText = "Nutri-decode营养解码，一家专注于精准营养定制的科技公司，在美国硅谷和台湾拥有营养研发和定制实验中心，科学团队整合基因组学，生物分子，生机食疗，胜肽，纳米材料以及人工智能等多学科的博士和科学家，脂在通过智能穿戴和生物标记物准确分析客户能量与代谢，激素，肠道和基因等健康状况，根据客户的目标选择不同，运用东方传统医学，万S养学和现代生物科学等定制一份个性化饮食和精准营养处方建议，致力帮助客户激发身体活力，提高睡眠质量，降低伤痛，提高代谢，并塑造”S“曲线，改善亚健康和远离慢病。";
                item.usaTip = true;
                item.productType = true;

            } else if (item.brandName == '君丽斯科技') {
                item.logoImg = `${this.$imgDomain}/www/HealthingCore/三态功养.png`;
                item.subtitle = "开启你的代谢健康新生活";
                item.statement = statement;
                item.decoding = "欢迎进入全新的代谢管理世界，还你完美曲线，实现健康人生";
                item.decodingText = "Nutri-decode营养解码，一家专注于精准营养定制的科技公司，在美国硅谷和台湾拥有营养研发和定制实验中心，科学团队整合基因组学、生物分子、生机食疗、胜脑、纳米材料以及人工智能等多学科的博士和科学家旨在通过智能穿戴和生物标记物准确分析客户能量与代谢、激素、肠道和基因等健康状况，根据各尸的目标选挥不同，运用东万传统医字四万旦养学和现代生物科学等定制一份个性化饮食和精准营养处方建议，致力帮助客户激发身体活力，提高睡眠质量，降低伤痛，提高代谢，并塑造“S曲线，改善亚健康和远离慢病。";
                item.usaTip = true;
                item.productType = true;

            } else if (item.brandName == '生命之鼎') {
                item.logoImg = `${this.$imgDomain}/www/HealthingCore/生命之鼎.png`;
                item.subtitle = "开启你的健康变形计重塑原本健康的你";
                item.statement = [`本产品为${item.productName}，不用于治疗、诊断、预防或治愈疾病。`, '本产品采用密封包装,收到请尽快使用', '请详细阅读说明书，按使用说明及顺序服用。'];
                item.decoding = "欢迎走进个性化营养定制时代";
                item.decodingText = "在营养健康领域，一场新的革命正在进行中，因为科学的不断进步，在营养、基因、生物学和智能科学关联领域研究的不断突破，私人定制的营养时代已经来临，营养不再是辅助角色而是临床医学重要干预手段生命之鼎个性化营养定制，是基于功能医学精准分析，饮食及生活方式分析、基因型分析三个维度的综合型检测评估;在“千人千面”的多维度精准分析基础上，综合考虑了检测的便捷性和动态性需求，以头发，于血片检测对个体进行科学精准分析，通过智能化报告分析，AI定制系统，定制专届的个性化定制营养素，科学检测-精准评估-个性定制-开启你的365天健康变形记";
                item.usaTip = false;
                item.productType = false;

            } else if (item.brandName == '植萃新安宁') {
                item.logoImg = `${this.$imgDomain}/www/HealthingCore/江南西.png`;
                item.subtitle = "开启你的细胞营养健康新生活";
                item.statement = statement;
                item.decoding = "欢迎进入营养解码，我们思考的是如何让你活的更年轻、更健康、更长久!";
                item.decodingText = "薇凯健康管理中心，集结全球先进医疗技术和国际权威医生团队，以营养、情绪、运动、医疗、环境五维健康管理为核心，科学团队整合基因组学、生物分子、生机食疗、胜肤、纳米材料以及人工智能等多学科的博士和科学家，旨在通过智能穿戴和生物标记物准确分析客户能量与代谢、激、肠道和基因等健康状况，根据客尸的目标选择不，运用东万传统医学、西方营养学和现代生物科学等定制一份个性化饮食和精准营养处方建议，致力帮助客户激发身体活力，提高睡眠质量，降低伤痛，提高代谢并塑造”S”曲线，改善亚健康和远离慢病。";
                item.usaTip = true;
                item.productType = true;

            } else if (item.brandName == '医希') {
                item.logoImg = `${this.$imgDomain}/www/HealthingCore/医希.png`;
                item.subtitle = "开启你的细胞营养健康新生活";
                item.statement = statement;
                item.decoding = "欢迎进入营养解码，我们思考的是如何让你活的更年轻、更健康、更长久!";
                item.decodingText = "1369定制最适合你的代谢方案，科学检测，精准分析，让身体说话携手全球健康科技优质院企，跨越5大洲，甄选全球最佳原产地原料个性化多维配方设计，走进健康代谢新生活，吃出好身材:1个目标:从根本上解决代谢问题3个时期/3个年轻态:系统管理代谢机制，让身体达到3个年轻态身型年轻态，精神年轻态，皮肤年轻态6大通道:同时打通代谢六大通道9个流程/9大改变:9个标准化流程带来身体9大改变体感、体重、体脂、体能、体态、体貌、体型、体质、体龄";
                item.usaTip = true;
                item.productType = true;

            } else if (item.brandName == '营养解码') {
                item.logoImg = `${this.$imgDomain}/www/HealthingCore/营养解码.png`;
                item.subtitle = "开启你的代谢健康新生活";
                item.statement = statement;
                item.decoding = "欢迎进入全新的代谢管理世界，还你完美曲线实现健康人生!";
                item.decodingText = "Nutri-decode营养解码，一家专注于精准营养定制的科技公司，在美国硅谷和台湾拥有营养研发和定制实验中心，科学团队整合基因组学、生物分子、生机食疗、胜胁、纳米材料以及人工智能等多学科的博士和科学家旨在通过智能穿戴和生物标记物准确分析客户能量与代谢、激素、肠道和基因等健康状沉，根据客尸的目标选择不问，运用东万传统医字、四万宫养学和现代生物科学等定制一份个性化饮食和精准营养处方建议，致力帮助客户激发身体活力，提高睡眠质量，降低伤痛，提高代谢，并塑造”S曲线，改善亚健康和远离慢病。";
                item.usaTip = true;
                item.productType = true;

            }
        }
    },
    watch: {

    },
    computed: {

    },
}
</script>
<style lang='scss' scoped>
.instructions {
    width: 100%;
    height: 100%;

    .content {
        width: 550px;
        height: 1694px;
        padding: 0 40px;

        .contItem {
            width: 100%;
            height: 420px;
            padding: 30px 0 15px 0;
        }

        .user {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 40px;
        }

        .head {
            .logo {
                width: 80px;
            }

            .headTitle {
                font-size: 22px;
                margin-top: 35px;

                .subtitle {
                    font-size: 20px;
                    margin: 5px 0 10px 0;
                }
            }

            .statement {
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                height: 150px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding: 10px 0;
            }
        }

        .details {
            .delHead {
                font-size: 22px;
            }

            .decoding {
                margin: 20px 0;
                font-size: 18px;
            }

            .text {
                line-height: 22px;
                padding-bottom: 20px;
                border-bottom: 1px solid #000;
            }
        }

        .usage {
            .usaHead {
                font-size: 22px;
                padding-bottom: 5px;
                margin-bottom: 5px;
                border-bottom: 1px solid #000;
            }

            .usageData {
                display: flex;
                justify-content: space-around;

                // margin-top: 40px;
                .usageItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 20%;

                    .methodImg {
                        // width: 100%;
                        // margin-bottom: 20px;
                    }
                }
            }
        }

        .component {
            .comHead {
                font-size: 22px;
            }

            .comtitle {
                padding: 10px 0;
                font-size: 14px;
                border-bottom: 1px solid #000;
            }

            .componentData {
                display: flex;
                height: 258px;
                flex-direction: column;
                justify-content: space-between;

                .compItem {
                    display: flex;
                    flex: 1;
                    border-bottom: 1px solid #000;
                    width: 100%;
                    align-items: center;
                    padding: 0 10px;

                    .compImg {
                        width: 60px;
                        height: 60px;
                        margin-right: 40px;
                    }

                    .compText {
                        flex: 1;
                    }
                }
            }
        }

        .food {
            .foodtitle {
                font-size: 14px;
                margin-bottom: 5px;
            }

            .foodImg {
                width: 100%;
                height: calc(100% - 25px);
            }
        }

        .introduce {
            .introduceItem {
                width: 100%;

                .basics {
                    display: flex;

                    .basicsItem {
                        width: 50%;
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                    }

                    .basicsItemSingle {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                    }
                }

                .introduceImg {
                    width: 100%;
                    margin-top: 40px;
                }
            }
        }
    }
}</style>